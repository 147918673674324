@charset "utf-8";
/* CSS Document */
@font-face {
    font-family: 'museo';
    src: url('../assets/font/museo300-regular-webfont.eot');
    src: url('../assets/font/museo300-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/font/museo300-regular-webfont.woff2') format('woff2'),
         url('../assets/font/museo300-regular-webfont.woff') format('woff'),
         url('../assets/font/museo300-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
hr {
    border: 1px solid #ebebeb;
    margin: 0;
}
h2, h3, h4 {
    font-family: 'museo', Arial, sans-serif;
}
h2 {
    font-size: 100px;
    margin-bottom: 60px;
}
h3 {
    font-size: 30px;
    line-height: 32px;
}
h4.why-store {
    font-size: 20px;
    color: red;
    margin:0;
    margin-top: 20px;
}
body {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	color: #333;
	margin: 0;
	padding: 0;
	background-image: url('../assets/img/bg-page.gif');
	background-repeat: repeat-y;
	background-position: center;
	background-color: #E8EFF2;
	line-height: 18px;
}

div.headerWrapper {
	background-color: #21353F;
}
#top {
    line-height: 0;
}
.logo { max-width: 100%; }

div.bannerWrapper {
	background-image: url('../assets/img/bg-banner.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-color: #85C7E9;
	height: 323px;
}

div.bannerHolder {
	position: relative;
	width: 960px;
	height: 323px;
	overflow: hidden;
	margin: 0 auto;
}

div.bannerContainer .text {
    width: 100%;
    padding-left: 310px;
    padding-right: 20px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
div.contactContainer {
	padding-left: 310px;
    padding-right: 20px;
}
.bannerContainer.alarm .text,
.alarm .contactContainer {
    padding-left: 350px;
}
.bannerContainer.on-site .text,
.on-site .contactContainer{
    padding-left: 354px;
}
.bannerContainer.guarantee .text,
.guarantee .contactContainer {
    padding-left: 280px;
}
.bannerContainer.clever .text,
.clever .contactContainer {
    padding-left: 356px;
}

div.bannerContainer .text p {
    font-size: 21px;
    line-height: 25px;
}

.banner-shaddow {
    position: absolute;
    top: 217px;
    left: 30px;
    width: 200px;
}
.alarm .banner-shaddow {
    left:42px;
    width: 240px;
}
.on-site .banner-shaddow {
    left: 40px;
    width: 250px;
}
.guarantee .banner-shaddow {
    left: 42px;
    width: 130px;
}

div.contentWrapper {
	background-color: #161616;
	color: #D8D2C9;
}
div.contentContainer {
	width: 960px;
	margin: 0 auto;
	position: relative;
}

div.menuWrapper {
	position: absolute;
	right: 0;
	bottom: 0;
	background-image: url('../assets/img/menu-bg.gif');
	width: 518px;
	height: 45px;
}

#mobile {
    display: none;
    font-family: 'museo', Arial, sans-serif;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    list-style-type: none;
    width: 100%;
    float: none;
    margin: 0;
    padding:0;
}
#mobile li {
    display: inline-block;
    background-image: none;
    padding-left: 0;
    margin-bottom: 0;
}
#mobile li > a {
    display: inline-block;
    color: #d7edf8;
    padding: 10px;
}
#mobile li > a:hover {
    text-decoration: none;
}

.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: white !important;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}

div.footerWrapper {
	background-color: #21353F;
}

div.footerContainer {
	width: 856px;
	margin: 0 auto;
	height: 250px;
	color: #8DCBEB;
}

div.textCenter {
	text-align: center;
}

div.contentContainer p.marginRight,
p.contactForm {
    display:inline-block;
    width: 50%;
}
div.contentContainer p.marginRight {
	padding-right: 40px;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    margin-bottom:0;
}

p.send {
    text-align:left;
    margin-top: 0;
    margin-bottom: 100px;
}

div.floatLeft {
	float: left;
}

div.floatRight {
	float: right;
}

div.clearBoth {
	clear: both;
}

div.menuItem {
	float: left;
	height: 45px;
	overflow: hidden;
}

div.bannerContainer {
	position: absolute;
	width: 100%;
	left: -960px;
	top: 0;
}

div.bannerIcon {
	position: absolute;
	left: 60px;
	top: 30px;
}
.need .bannerIcon {
    top:53px;
}
.alarm .bannerIcon,
.guarantee .bannerIcon,
.clever .bannerIcon {
    top: 60px;
}
.clever .bannerIcon {
    left: 42px;
}
.on-site .bannerIcon {
    left: 40px;
}

div.bannerButtonHolder {
	position: absolute;
	top: 295px;
	left: 60px;
	height: 16px;
}

div.bannerButton {
	height: 16px;
	width: 16px;
	background-color: #21353f30;
	border-radius: 50%;
	cursor: pointer;
	float: left;
	margin: 0 5px 0 0;
}

div.bannerButtonActive {
	height: 16px;
	width: 16px;
	background-color: #21353f;
}

div.headerContainer {
	border-bottom: dotted 1px #8A7967;
	padding-bottom: 15px;
}

div.topContainer {
	position: relative;
	text-align: right;
	background-image: url('../assets//img/shadow-box.gif');
	background-repeat: no-repeat;
	background-position: right 33px;
	height: 40px;
	padding-top: 5px;
}
.topContainer > a {
    display: block;
    padding-right: 50px;
    color: #d6d6d6 !important;
}
div.topButton {
	position: absolute;
	top: 0;
	right: 6px;
	height: 40px;
}

div.menuItem a {
	display: block;
	height: 45px;
}

div.sectionContainer {
	margin: 50px 0 0 0;
	position: relative;
	overflow: hidden;
}

div.contentIconHolder {
	background-image: url('../assets/img/white-glow.png');
	background-repeat: no-repeat;
	background-position: center -16px;
	width: 220px;
	height: 150px;
	text-align: center;
	position: relative;
	padding-top: 140px;
	float: left;
}

div.contentIcon {
	position: absolute;
    top: 20px;
    left:0;
    width: 100%;
    height: 120px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.owner {
    background-image:url('../assets/img/icon-man.png');
}
.clipboard {
    background-image:url('../assets/img/icon-clipboard.png')
}
.calculator {
    background-image:url('../assets/img/icon-calculator.png');
}
.c247 {
    background-image:url('../assets/img/icon-phone.png');
}
.owner-shaddow,
.clipboard-shaddow,
.calculator-shaddow,
.c247-shaddow {
    max-width: 100%;
}

div.contentText {
	float: left;
	width: 730px;
	margin: 20px 0 0 0;
}

div.photoWrapper {
	position: relative;
	width: 350px;
	height: 310px;
	margin-top: -360px;
	float: right;
	overflow: hidden;
}

div.photoContainer {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: -350px;
}
.photoContainer > img {
    max-width: 100%;
    max-height:100%;
}

img.floatLeft {
	float: left;
	margin: 5px 15px 15px 0;
}

a {
	color: #F5454A;
	text-decoration: none;
}

a:link {
	color: #F5454A;
	text-decoration: none;
}

a:visited {
	color: #F5454A;
	text-decoration: none;
}

a:active {
	color: #F5454A;
	text-decoration: underline;
}

a:hover {
	color: #F5454A;
	text-decoration: underline;
}

a.footerLink {
	color: #8DCBEB;
	text-decoration: none;
}

a.footerLink:link {
	color: #8DCBEB;
	text-decoration: none;
}

a.footerLink:visited {
	color: #8DCBEB;
	text-decoration: none;
}

a.footerLink:hover {
	color: #8DCBEB;
	text-decoration: underline;
}

a.footerLink:active {
	color: #8DCBEB;
	text-decoration: underline;
}

a.downloadLink {
	color: #FFF;
	text-decoration: none;
}

a.downloadLink:link {
	color: #FFF;
	text-decoration: none;
}

a.downloadLink:visited {
	color: #FFF;
	text-decoration: none;
}

a.downloadLink:hover {
	color: #FFF;
	text-decoration: underline;
}

a.downloadLink:active {
	color: #FFF;
	text-decoration: underline;
}

ul {
	float: left;
}

li {
	list-style-position: inside;
	list-style-type: none;
	background-image: url('../assets/img/tick-red.png');
	background-repeat: no-repeat;
	background-position: 0 3px;
	padding-left: 20px;
	line-height: 18px;
	margin-bottom: 15px;
}

.contact-details {
    display: inline-block;
    width: 20%;
}
.social-link-icon {
    width: 48px;
    height: 48px;
}
form {
    display: inline-block;
    width: 80%;
}
span.inputRequired {
	float: right;
	color: #E2380C;
}
.wrapper {
    position: relative;
    padding-bottom: 20px;
}
span.wrapper {
    display: inline-block;
    width: 100%;
}
label.infield {
    position: absolute;
    padding: 10px;
}
.error {
    color: red !important;
}
input.textBox {
	background-color: #FFF;
	border: solid 2px #DCE4E6;
	padding: 10px;
	width: 100%;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	color: #333;
    box-sizing: border-box;
}

textarea.textareaBox {
	background-color: #FFF;
	border: solid 2px #DCE4E6;
	padding: 10px;
	width: 100%;
	height: 127px;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	color: #333;
    box-sizing: border-box;
}

/********** media querys **********/
@media screen and (max-width:980px) {
    div.bannerHolder {
        width: 100%;
    }
    div.bannerContainer .text {
        padding-top: 40px;
        padding-left: 310px;
    }
    .bannerContainer.alarm .text,
    .alarm .contactContainer,
    .bannerContainer.on-site .text,
    .on-site .contactContainer {
        padding-left: 310px;
    }
    .bannerContainer.clever .text,
    .clever .contactContainer {
        padding-left: 280px;
    }
    .bannerContainer.guarantee .text,
    .guarantee .contactContainer {
        padding-left: 200px;
    }
    h2 {
        font-size: 30px;
        margin-bottom: auto;
    }
    div.bannerContainer .text p {
        font-size: 18px;
        line-height: 20px;
    }
    div.contentContainer {
        width: 90%;
    }
    div.contentIconHolder {
        width: 20%;
    }
    .calculator-shaddow,
    .c247-shaddow {
        height: 20px;
        width: 110%;
    }
    div.contentText {
        width: 80%;
        padding-left: 4%;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    div.photoWrapper {
        float:none;
    }
    .contact-details,
    form {
        width: 100%;
    }
    .contact-details {
        clear: left;
    }
    div.footerContainer {
        width: 90%;
        height: 100px;
    }
}
@media screen and (max-width: 900px){
    div.headerWrapper div.contentContainer {
        padding-bottom: 45px;
    }
}
@media screen and (max-width:650px){
    /* iframe calculator */
    #storage-calc-calculator-form {
        width: 100%;
        margin: 0;
    }
    iframe body {
        background: #FFF !important;
    }
}
@media screen and (max-width:600px){
    div.headerWrapper div.contentContainer {
        text-align: center;
    }
    /* mobile menu */
    div.headerWrapper div.contentContainer {
        padding-bottom: 0;
    }
    .menuWrapper { display: none; }
    #mobile {
        display: block;
    }
    div.contentContainer p.marginRight,
    div.contentContainer p.contactForm {
        width: 100%;
    }
    div.contentContainer p.marginRight {
        padding-right:0;
    }
    div.contentText {
        margin:0;
    }
    h3 {
        margin-top: 10px;
    }
}
@media screen and (max-width: 550px){
    /* banner icons GONE text full width */
    div.bannerIcon,
    .banner-shaddow {
        display: none;
    }
    div.bannerContainer .text,
    .bannerContainer.alarm .text,
    .bannerContainer.on-site .text,
    .bannerContainer.guarantee .text,
    .bannerContainer.clever .text {
        padding:40px;
        padding-bottom: 10px;
    }
    div.contactContainer,
    .alarm .contactContainer,
    .on-site .contactContainer,
    .guarantee .contactContainer,
    .clever .contactContainer{
        padding: 0 40px;
    }
    div.bannerButtonHolder {
        left: 50%;
        margin-left: -52.5px;
    }
    /* content */
    div.sectionContainer {
        margin:0;
    }
     div.contentIconHolder,
     div.contentText {
        display: block;
        width: 100%;
    }
    div.contentIconHolder {
        height: 80px;
    }
    div.contentIconHolder.first {
        height: 60px;
    }
    .calculator-shaddow,
    .c247-shaddow {
        width:auto;
        height: auto;
    }
    div.contentText {
        padding-left: 0;
    }
    div.photoWrapper {
        margin: 0 auto;
    }
    #storage-calc-calculator-form,
    table.storage-calc,
    tr {
        width: 100%;
    }
    p.send {
        margin-bottom: 40px;
    }
}
@media screen and (max-width:460px){
    div.photoWrapper,
    div.photoContainer {
        width: 100%;
    }
    div.footerContainer {
        text-align:center;
    }
    div.floatLeft,
    div.floatRight {
        float:none;
    }
}
@media screen and (max-width:400px){
    div.bannerContainer .text,
    .bannerContainer.alarm .text,
    .bannerContainer.on-site .text,
    .bannerContainer.guarantee .text,
    .bannerContainer.clever .text {
        padding-top: 20px;
    }
    ul {
        padding: 0;
    }
}
